import React, { createElement } from "react";
import * as ReactDOMClient from 'react-dom/client';
import AppSettingsProvider from "./contexts/AppSettingsProvider";
import MeetupsPage from "./features/Meetups/Meetups.page";
import MeetupNotification from "./features/Meetups/components/MeetupNotification";
import WeekScheduler from "./pages/WeekScheduler";

const COMPONENTS = {
  MeetupNotification,
  MeetupsPage,
  WeekScheduler,
};

document.addEventListener("turbolinks:load", () => {
  const reactDomNodes = [...document.querySelectorAll(".react-page")]
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  const container = document.getElementById('react-app');
  const root = ReactDOMClient.createRoot(container);
  const lang = document.documentElement.lang;
  const dictionary = window.rails_I18n;

  const App = () => {
    return(
      <AppSettingsProvider csrfToken={csrfToken} lang={lang} dictionary={dictionary}>
        { reactDomNodes.map((node, i) => createElement(COMPONENTS[node.dataset.component], {key: i})) }
      </AppSettingsProvider>
    )
  }
  root.render(<App/>);
});
