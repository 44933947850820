import I18n from "../../utils/i18n";
import {useAppSettings} from "../contexts/AppSettingsProvider";

const useI18n = () => {
    const { dictionary, lang } = useAppSettings();
    const locale = lang;

    const t = (key, options = null) => new I18n(dictionary).t(key, options);
    return { t, locale }
}

export default useI18n;