import React, { useState, useEffect, useRef } from "react";
import("./weekScheduler.css");
import SchedulerContext from "../contexts/SchedulerContext";
import Calendar from "../components/Scheduler/Calendar";
import SavePrompt from "../components/Scheduler/SavePrompt"
import { useAppSettings } from "../contexts/AppSettingsProvider";
import apiCall from "../utils/apiHelper";
import {
  createTimeGrid,
  getTimingById,
  generateUpdatedTimeSlot,
  findOverlappingSlots,
  mergeSlots,
  calcTimeDiffInMinutes,
  defaultTimeSlotHeight,
} from "../components/Scheduler/helper";

import useI18n from "../hooks/useI18n";

const WeekScheduler =()=> {
  const { csrfToken } = useAppSettings();
  const initTimeGrid = useRef(null); // A ref holding the initial time grid state used to compare with the current `timeGrid` for detecting changes.
  const [timeGrid, setTimeGrid] = useState([]); // Array of time slots
  const [loading, setLoading] = useState(true); // A boolean flag indicating whether the scheduler data is being loaded from the API
  const [showAlert, setShowAlert] = useState(false); //A boolean flag that determines if an alert (e.g., "Schedule saved successfully") should be displayed.
  const [editable, setEditable] = useState(true); // A boolean flag indicating whether the scheduler is editable or not
  const [error, setError] = useState(false); // A boolean flag for Error Msg
  const [showAlertError, setShowAlertError] = useState(false); //A boolean flag that determines to display error Alert
  const [hasChanged, setHasChanged] = useState(false) // A boolean flag that checks if save button has been clicked or not

  const { t } = useI18n();


  // function to fetch data initially
  const fetchData = async () => {
    try {
      // Fetch data using an API call
      const data = await apiCall(
        "GET",
        "availabilities/get_timings",
        csrfToken
      );

      // Generate an initial time grid based on fetched timings
      const initGrid = await createTimeGrid(data?.timings);
      setTimeGrid(initGrid);
      setEditable(data.editable)
      initTimeGrid.current = JSON.parse(JSON.stringify(initGrid));
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /* function to check if two timeSlots are overlapping, merge them if they overlap
 and updateTimeGrid accordingly */

  const checkOverlapAndMerge = ({
    id, // Identifier for the current time slot
    startTime, // Start time of the current time slot
    endTime, // End time of the current time slot
    dayIndex, // Index indicating the day of the week
    height, // Height value representing the duration of the time slot visually
  }) => {
    // Find time slots that overlap with the current slot
    const overlappingSlots = findOverlappingSlots(
      startTime,
      endTime,
      dayIndex,
      id,
      timeGrid
    );

    // Boolean value to determine if there are any overlapping slots
    const isOverlapping = overlappingSlots.length > 0;

    let newTimeGridObj;

    if (!isOverlapping) {
      // If there's no overlap, create a new time grid object with the given details
      newTimeGridObj = {
        dayIndex,
        endTime,
        height,
        id,
        isEmpty: false, // Flag to denote if the slot is empty
        startTime,
      };
    } else {
      // If overlapping, merge the overlapping slots into one
      const { mergedStartTime, mergedEndTime } = mergeSlots(
        startTime,
        endTime,
        overlappingSlots
      );

      // Calculate the height for the merged slot based on the time difference
      const heightAfterMerge = calcTimeDiffInMinutes(
        mergedEndTime,
        mergedStartTime
      );

      // Choose the ID of the first overlapping slot for the merged slot
      const idAfterMerge = overlappingSlots[0].id;

      // Define the merged time grid object
      newTimeGridObj = {
        dayIndex,
        endTime: mergedEndTime,
        height: heightAfterMerge,
        id: idAfterMerge,
        isEmpty: false,
        startTime: mergedStartTime,
      };

      // Delete current slot
       // Define the empty slot to delete
       const slotToDelete = {
        dayIndex,
        id,
        isEmpty: true, // Flag to denote the slot is empty
        startTime: "00:00",
        endTime: "00:00",
        height: defaultTimeSlotHeight, // Default height for the time slot
      };

      // Update the time grid to delete the specified slot
      updateTimeGrid(slotToDelete);

      // Delete remaining merged slots of timeSlot
      for(let i=1; i<overlappingSlots.length; i++){
        const slotIdToDelete =overlappingSlots[i].id

        // Define the empty slot to delete
        const slotToDelete = {
          dayIndex,
          id: slotIdToDelete,
          isEmpty: true, // Flag to denote the slot is empty
          startTime: "00:00",
          endTime: "00:00",
          height: defaultTimeSlotHeight, // Default height for the time slot
        };

        // Update the time grid to delete the specified slot
        updateTimeGrid(slotToDelete);
      }
    }

    // Update the time grid with the new or merged slot
    updateTimeGrid(newTimeGridObj);
  };

  // function to delete timeSlot entry from timeGrid array

  const deleteFromTimeGrid = async (idToDelete) => {
    // Set the updated time grid state by filtering out the time slot with the specified ID
    setTimeGrid((prevTimeGrid) => {
      // Previous state of the time grid
      // Filter out the time slot that matches the provided ID to delete
      let updatedGrid = prevTimeGrid.filter((grid) => grid.id !== idToDelete);

      return updatedGrid;
    });
  };

  // A function to update the current time grid with a new or modified time slot

  const updateTimeGrid = (newTimeGrid) => {

    if(!hasChanged) setHasChanged(true)

    // Destructure properties from the new time slot
    const { id, startTime, isEmpty } = newTimeGrid;

    // If the time slot starts at "00:00", has an id that includes "id", and is marked as empty, delete it from the time grid
    if (startTime === "00:00" && id.includes("id") && isEmpty) {
      deleteFromTimeGrid(id);
    }

    // Update the state of the time grid
    setTimeGrid((prevTimeGrid) => {
      // Previous state of the time grid
      // Create a deep copy of the previous time grid
      let updatedGrid = JSON.parse(JSON.stringify(prevTimeGrid));

      // Find the index of the time slot in the updated grid that matches the ID of the new time slot
      let foundIndex = updatedGrid.findIndex((grid) => grid.id === id);

      if (foundIndex !== -1) {
        // Get the initial ID of the found time slot
        const initId = updatedGrid[foundIndex].id;

        // If the initial ID does not include "id", retain the initial ID and update the time slot details
        if (!initId.includes("id")) {
          const updatedSlot = { ...newTimeGrid, id: initId };
          updatedGrid[foundIndex] = updatedSlot;
        } else {
          updatedGrid[foundIndex] = newTimeGrid;
        }
      } else {
        updatedGrid.push(newTimeGrid);
      }

      return updatedGrid;
    });
  };

  // function to display alert message on successfully saving the scheduler
  const displayAlert = () =>{
    setShowAlert(true)
    // Schedule to hide the alert after 3 seconds
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
}

  // function to call add_timings api
  const handleAddTimings = async (addTimingsArray) => {
      const response = await apiCall(
        "POST",
        "availabilities/add_timings",
        csrfToken,
        {
          new_timings: addTimingsArray,
        }
      );
      for (const map of response.id_map) {
        // id_map = [record_id, frontend_id]
        const recordId = map[0];
        const frontEndId = map[1];
        const slotObj = getTimingById(timeGrid, frontEndId); // fetch the slot by existing frontend_id
        deleteFromTimeGrid(frontEndId);
        const newSlotObj = { ...slotObj, id: String(recordId) }; // Create new slot by replacing old frontend_id with record_id
        updateTimeGrid(newSlotObj);
      }
       // Show an alert on the UI
       displayAlert()   
  };

  // function to call delete_timings api
  const handleDeleteTimings = async (deleteTimingsArray) => {
      await apiCall(
        "DELETE",
        "availabilities/delete_timings",
        csrfToken,
        {
          ids: deleteTimingsArray,
        }
        
      );
      // Show an alert on the UI
      displayAlert()
  
  };

  // function to call update_timings api
  const handleUpdateTimings = async (updateTimingsArray) => {
       await apiCall(
        "PATCH",
        "availabilities/update_timings",
        csrfToken,
        {
          update_timings: updateTimingsArray,
        }
      );
    // Show an alert on the UI
    displayAlert()
  };

  /* function that get called when we click save button
  it compares timeGrid array with initTimeGrid array and finds timeSlot entries that 
  are added, updated, and deleted */

  const handleClick = async () => {
   
    // Arrays to classify which time slots need to be added, updated, or deleted
    const addTimingsArray = [];
    const updateTimingsArray = [];
    const deleteTimingsArray = [];

    for (let i = 0; i < timeGrid.length; i++) {
      const newObj = timeGrid[i];

      // Generate an updated time slot with formatted timing details
      const updatedObj = generateUpdatedTimeSlot(
        newObj.startTime,
        newObj.endTime,
        newObj.dayIndex
      );

      // Find the original time slot (if it exists) from the initial time grid
      const initObj = initTimeGrid.current.find(
        (item) => item.id === newObj.id
      );

      // Determine if the current time slot ID is temporary (e.g., "id12345")
      const isTempId = newObj.id.includes("id");

      if (!initObj && newObj.startTime !== "00:00") {
        addTimingsArray.push([
          newObj.id,
          updatedObj.startTime,
          updatedObj.endTime,
        ]);
      } else {
        if (!isTempId) {
          if (newObj.isEmpty === true || newObj.startTime === "00:00") {
            deleteTimingsArray.push(newObj.id);
            deleteFromTimeGrid(newObj.id);
          } else if (
            newObj.id === initObj.id &&
            !(
              newObj.height == initObj.height &&
              newObj.startTime == initObj.startTime &&
              newObj.endTime == initObj.endTime
            )
          ) {
            updateTimingsArray.push([
              newObj.id,
              updatedObj.startTime,
              updatedObj.endTime,
            ]);
          }
        }
      }
    }

    try {
      // Make API calls based on the required actions (delete, add, update)
      if (deleteTimingsArray.length > 0) {
        await handleDeleteTimings(deleteTimingsArray);
      }
      if (addTimingsArray.length > 0) {
        await handleAddTimings(addTimingsArray);
      }
      if (updateTimingsArray.length > 0) {
        await handleUpdateTimings(updateTimingsArray);
      }
      if(hasChanged) setHasChanged(false);
    } catch (err) {
      setShowAlertError(true) 
      if(!hasChanged) setHasChanged(true);
    }

    // Update the initial time grid to the current state for reference in future interactions
    initTimeGrid.current = JSON.parse(JSON.stringify(timeGrid));

  };



return(

  error ? (
    <div data-testid="error">
      {t('react.week_scheduler.page_loading_error')}
    </div>
  ) : (
    <div className="week-scheduler">  
      {loading ? (
        <div data-testid="loading">
          {t('react.week_scheduler.loading')}
        </div>
      ) : (
        <>
          <SavePrompt hasUnsavedChanges={hasChanged} />
          <SchedulerContext.Provider value={{ editable }}>
            {editable && (
              <div className="header">
                {showAlert && (
                  <div className="scheduler-alert success" data-testid="success-alert">
                    {t('react.week_scheduler.save_schedule_success_alert')}
                  </div>
                )}

                {showAlertError && (
                  <div className="scheduler-alert error" data-testid="error-alert"> 
                    {t('react.week_scheduler.save_schedule_error_alert')}
                  </div>
                )}

                {!showAlert && !showAlertError && 
                    (<div className="scheduler-alert">&nbsp;</div>
                    )}

                <div className="header-btn-wrapper">
                  <button
                    className="btn btn-primary mr-2"
                    data-testid="save-btn"
                    type="button"
                    onClick={handleClick}
                  > 
                    {t('react.week_scheduler.save_schedule')}
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => setTimeGrid(initTimeGrid.current)}
                  > 
                    {t('react.week_scheduler.clear_changes')}
                  </button>     
                </div>
              </div>
            )}

            <Calendar
              timeGrid={timeGrid}
              updateTimeGrid={updateTimeGrid}
              checkOverlapAndMerge={checkOverlapAndMerge}
            />
          </SchedulerContext.Provider>
        </>
      )}
    </div>
  )
)

}

export default WeekScheduler;
