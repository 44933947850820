import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import PropTypes from "prop-types";
import {
  days,
  timeSlots,
  findTimeIndex,
  calculatePosXY,
  defaultTimeSlotHeight,
  createInitTimings,
} from "./helper";
import useI18n from "../../hooks/useI18n";
import TimeSlot from "./TimeSlot";
import SchedulerContext from "../../contexts/SchedulerContext";

const Calendar = ({
  timeGrid,
  updateTimeGrid,
  checkOverlapAndMerge,
}) =>{
  const { t } = useI18n();
  const timeSlotBoard = useRef(null); // Ref to the div representing the board of time slots.
  const [boardPosition, setBoardPosition] = useState({}); // Represents the position of the time slot board on the screen.

  const [timeSlotPositions, setTimeSlotPositions] = useState([]); // Array of positions (x,y) of each time slot on the board.
  const { editable } = useContext(SchedulerContext); // Accessing the editable flag from the Scheduler context to determine if the slot can be modified

  useEffect(() => {
    // Update the positions of time slots based on the current time grid and board position
    setTimeSlotPositions(
      timeGrid.map((slot, index) => {
        // Loop through each slot in the time grid
        const { dayIndex, startTime } = slot; // Extract dayIndex and startTime from the current slot

        // Calculate the width for each time slot based on the overall board width
        const width = Math.floor(boardPosition.width / 8);

        // If the startTime of the slot is not "00:00"
        if (startTime !== "00:00") {
          // Split the startTime string into hours and minutes
          const splittedStTime = startTime?.split(":");

          // Determine the index for the start time hour
          const timeIndex = findTimeIndex(splittedStTime[0]);
         
          // Calculate the x and y positions for the slot based on its width, dayIndex, timeIndex, and start minute
          const pos = calculatePosXY(
            width,
            dayIndex,
            timeIndex,
            splittedStTime[1]
          );
 
          // Return an object with the calculated x and y positions and the current index
          return { posX: pos.x, posY: pos.y, index };
        }
      })
    );
  }, [boardPosition, timeGrid]); // The effect will re-run whenever boardPosition or timeGrid changes

  useLayoutEffect(() => {
    // Define a function to update the position and dimensions of the time slot board
    const updatePosition = () => {
      // Check if the reference to the time slot board exists
      if (timeSlotBoard.current) {
        // Get the position and dimensions of the time slot board relative to the viewport
        const boardPosition = timeSlotBoard.current.getBoundingClientRect();

        // Update the state with the new position and dimensions
        setBoardPosition(boardPosition);
      }
    };

    // Add an event listener to the window to trigger the update function whenever the window is resized
    window.addEventListener("resize", updatePosition);

    // Call the update function immediately to ensure we have the latest board position when the effect runs
    updatePosition();

    // Return a cleanup function to remove the event listener when the component is unmounted or before re-running the effect
    return () => window.removeEventListener("resize", updatePosition);
  }, []); // The effect has an empty dependency array, so it only runs once when the component mounts

  const handleCellClick = (e, dayIndex) => {
    
    // Get the position and dimensions of the time slot board relative to the viewport
    const calPos = timeSlotBoard.current.getBoundingClientRect();

    // Calculate the time index based on the vertical position of the click within the time slot board, assuming each hour slot is 60 pixels high
    const timeIndex = Math.floor((e.clientY - calPos.top) / 60);
    // Derive the initial start and end timings based on the time index
    const { initSt, initEt } = createInitTimings(timeIndex);

    // Create an initial ID for the new time slot based on the day and time index
    const initId = `id${dayIndex}${timeIndex}`;


    // Construct the new time slot object with the derived details
    const newTimeGridObj = {
      endTime: initEt,
      dayIndex: dayIndex,
      height: defaultTimeSlotHeight,
      id: initId,
      isEmpty: false,
      startTime: initSt,
    };
    // Check if the new time slot overlaps with any existing ones, and merge if necessary
    checkOverlapAndMerge(newTimeGridObj);
  };

  

  const timeSlotComponents = useMemo(() => {
    return timeGrid.map((timeSlotObj, index) => {
      const { id, dayIndex, startTime, endTime, height, isEmpty } = timeSlotObj;

      const timeSlotPosition = timeSlotPositions[index];
     
      return (
        startTime &&
        timeSlotPosition && (
          <TimeSlot
            data-testid="timeSlot"
            key={id}
            width={Math.floor(boardPosition.width / 8)}
            posX={timeSlotPosition.posX}
            posY={timeSlotPosition.posY}
            checkOverlapAndMerge={checkOverlapAndMerge}
            updateTimeGrid={updateTimeGrid}
            id={id}
            dayIndex={dayIndex}
            startTime={startTime}
            endTime={endTime}
            height={height}
            isEmpty={isEmpty}
          />
        )
      );
    });
  }, [timeGrid, boardPosition, timeSlotPositions]);

  return (
    <div
      data-testid="calendar-component"
      className="scheduler-container-fluid"
    >
      <div className="scheduler-row">
        <div className="scheduler-col">
          <div>{""}</div>
        </div>
        {days.map((day, dayIndex) => (
          <div className="scheduler-col day" data-testid="day" key={dayIndex}>
            {t(`date.day_short.${day}`)}
          </div>
        ))}
      </div>

      <div ref={timeSlotBoard} className="timeSlot-board" data-testid="timeSlot-board">
        {timeSlotComponents}
        {timeSlots.map((timeSlot, timeIndex) => (
          <div className="scheduler-row" data-testid="row" key={timeIndex}>
            <div className="scheduler-col time">{timeSlot}</div>
            {days.map((day, dayIndex) => (
              <div className="scheduler-col" key={`${dayIndex}-${timeIndex}`}>
                <div
                  data-testid={"cell" + `-${dayIndex}${timeIndex}`}
                  className={`cell-wrapper ${
                    dayIndex === 0 ? "first-col " : ""
                  }${timeIndex === 0 ? "first-row" : ""}`}
                  key={`${dayIndex}-${timeIndex}`}
                  onClick ={ editable ? (e) => handleCellClick(e, dayIndex): undefined}
                ></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

Calendar.propTypes = {
  timeGrid: PropTypes.arrayOf(
    PropTypes.shape({
      dayIndex: PropTypes.number.isRequired,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      height: PropTypes.number,
      id: PropTypes.string.isRequired,
      isEmpty: PropTypes.bool.isRequired,
    })
  ).isRequired,
  checkOverlapAndMerge: PropTypes.func.isRequired,
};

export default Calendar;