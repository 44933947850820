import React, {useState} from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import classNames from "classnames";
import('./MeetupNotification.css');
import moment from "moment";
import apiCall from "../../../utils/apiHelper";
import {useAppSettings} from "../../../contexts/AppSettingsProvider";
import useI18n from "../../../hooks/useI18n";

export default function MeetupNotification(props){
  const { t } = useI18n();
  const { csrfToken, lang } = useAppSettings();
  const {
    meetupPerson,
    personName,
    meetupData,
    ownChannel,
    urlKey,
    userArrived
  } = props;

  let { userDistance, counterpartDistance } = props;
  let { counterpartArrived } = props;
  let { userLastUpdatedAt, counterpartLastUpdatedAt } = props;

  const {address, meeting_datetime} = meetupData;
  const [show, setShow] = useState(true);
  const [lateDialogShow, setLateDialogShow] = useState(false);
  const lateMintues = [5, 10, 15, 20, 25, 30]
  const [selectedLateMinutes, setSelectedLateMintues] = useState("")
  const [toggleContents, setToggleContents] = useState(t('react.meetups.select_minutes'));
  const [comingModalShow, setComingModalShow] = useState(false);
  const [arrivedClicked, setArrivedClicked] = useState(false);
  const [userStatus, setUserStatus] = useState(userArrived);

  moment.locale(lang);
  const meetingTime = moment(meeting_datetime).fromNow();

  userDistance = isNaN(userDistance) ? userDistance : Math.round(userDistance * 10 / 1000) / 10
  counterpartDistance = isNaN(counterpartDistance) ? counterpartDistance : Math.round(counterpartDistance * 10 / 1000) / 10

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClickLateBtn = () => {
    setLateDialogShow(true)
  }

  const onCloseLateDialog = () => {
    setLateDialogShow(false)
  }

  const onClickComing = () => {
    setComingModalShow(true)
  }

  const onCloseComingDialog = () => {
    setComingModalShow(false)
  }

  // Updating the participant status
  function updatePersonStatus(arrived, lateMin){
    if (arrived || lateMin) {
      //If arrived, send "Arrived", if late, then send late
      if(arrived) {
        ownChannel.send({userArrived: true, thisPerson: meetupPerson, meetupKey: urlKey});
        setUserStatus(t('react.meetups.arrived'));
        setArrivedClicked(true);
      }
      else if (lateMin) {
        ownChannel.send({userLate: lateMin, thisPerson: meetupPerson, meetupKey: urlKey});
        setUserStatus(t('react.meetups.will_be_late_for', {minutes: lateMin}));
      }

      const dataHash = { arrived: arrived, late_min: lateMin, meetup_id: urlKey, buyer_seller: meetupPerson };

      apiCall("put", "/participants/update_current_status.json",
        csrfToken,
        dataHash )
        .then((res) => {
          console.log("Updated participant status successfully"); 
        })
        .catch((err) => console.log("Error", err));
    }
    onCloseLateDialog();
    onClickComing();
  }

  // Refresh button
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div>
      <Modal dialogClassName={classNames({"modal-container": lateDialogShow || comingModalShow})} centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {t('react.meetups.meetup_notification')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-notification">
            <span>
              {t('react.meetups.you_have_meetup', {meetingTime: meetingTime, personName: personName, address: address})}
            </span>
            <span className="coming-text">
              {t('react.meetups.are_you_coming')}
            </span>
            <div className="confirm-btn">
              <Button variant="primary btn-block mt-2" onClick={onClickComing} >
                {t('react.meetups.yes_coming')}
              </Button>

            </div>

            <div className="late-btn">
              <Button onClick={onClickLateBtn} variant="primary btn-block mt-2" >
                {t('react.meetups.will_be_late')}
              </Button>

            </div>
            <div className="contact-seller-btn">
              <Button href={meetupData.contact_path} variant="secondary btn-block mt-2" >
                {t('react.meetups.contact_counterpart', {personName: personName})}
              </Button>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={lateDialogShow} onHide={onCloseLateDialog}>
        <Modal.Body>
          <div className="row">
            <div className="col-6 mt-15 text-right">
              {t('react.meetups.i_will_be_late_for')}
            </div>
            <div className="col-6 late-mins-dropdown">
              <Dropdown
                onSelect={(eventKey) => {
                  setSelectedLateMintues(eventKey)
                  setToggleContents(
                    <>
                      {eventKey} mintues
                    </>
                  );
                }}
              >
                <Dropdown.Toggle variant="secondary btn-block" id="dropdown-basic">
                  {toggleContents}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    lateMintues.map((min, i) => (
                      <Dropdown.Item eventKey={min} key={i}>{t('react.meetups.min_to_be_late', {minutes: min})}</Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="submit-btn">
            <Button variant="primary btn-block mt-2" onClick={() =>updatePersonStatus(null, selectedLateMinutes)} >{t('react.meetups.submit')}</Button>
          </div>
          <div className="common-style">
            <Button href={meetupData.contact_path} variant="secondary btn-block mt-2" >
              {t('react.meetups.contact_counterpart', {personName: personName})}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={comingModalShow} onHide={onCloseComingDialog}>
        <Modal.Header>
          <Modal.Title>
            {t('react.meetups.latest_distance_to_meetup_location')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="common-style">
              {t('react.meetups.counterpart_distance_to_meetup_location')}
            </div>
            <div className="common-style">
              {counterpartArrived  != undefined ? counterpartArrived : ""}
            </div>

            <div className="common-style">
              { counterpartDistance == 'not_joined' ? t('react.meetups.not_joined') : counterpartDistance + ' km' }          
            </div>

            { counterpartDistance == 'not_joined' ? "" : 
            <div className="common-style">
              {t('react.meetups.last_updated_at', {updated_at: moment(counterpartLastUpdatedAt).fromNow()})}
            </div>
            } 
            <hr/>
            <div className="common-style">
              {t('react.meetups.your_distance_to_meetup_location')}
            </div>
            <div className="common-style"  >
              {userStatus  != undefined ? userStatus : ""}
            </div>

            { userDistance == 'calculating' ? 
              <div className="common-style">
                {t('react.meetups.calculating_distance')}
              </div>
              : 
              <div>
                <div className="common-style">
                  {userDistance} km
                </div>
                <div className="common-style">
                  {t('react.meetups.last_updated_at', {updated_at: moment(userLastUpdatedAt).fromNow()})}
                </div>
              </div>
            }

            <div className="common-style">
              { !arrivedClicked  ? <Button id="arrived" variant="primary btn-block mt-2" onClick={() =>updatePersonStatus(true, null)} >
                {t('react.meetups.i_have_arrived')}
              </Button> : t('react.meetups.arrived') }
            </div>
            <div className="common-style">
              <Button variant="primary btn-block mt-2" onClick={() =>refreshPage()} >
                {t('react.meetups.refresh_page')}
              </Button>
            </div>
            <div className="common-style">
              <Button href={meetupData.contact_path} variant="secondary btn-block mt-2" >
                {t('react.meetups.contact_counterpart', {personName: personName})}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </div>
  )
}
