import { Spinner } from "react-bootstrap";
import React, {useState, useEffect} from "react";
import MeetupNotification from "./components/MeetupNotification";
import { getDistance } from "geolib";
import consumer from "../../../../channels/consumer"
import apiCall from "../../utils/apiHelper";
import config from "../../configs";
import {useAppSettings} from "../../contexts/AppSettingsProvider";
import useI18n from "../../hooks/useI18n";

function getUrlKey() {
  const urlArray = window.location.pathname.split('/');
  if (urlArray.includes("meetups")){
    return urlArray[urlArray.length -1]
  } else {
    return false
  }
}

export default function MeetupsPage() {
  const { t } = useI18n();
  const { csrfToken } = useAppSettings();
  const [urlKey, setUrlKey] = useState(getUrlKey());
  const [meetupPerson, setMeetupPerson] = useState(null)
  const [meetupError, setMeetupError] = useState(null);
  const [meetupLoading, setMeetupLoading] = useState(false);
  const [meetupLoaded, setMeetupLoaded] = useState(false);
  const [meetupData, setMeetupData] = useState(null);
  const [meetupList, setMeetupList] = useState(null);
  const [ownChannel, setOwnChannel] = useState(null);
  const [theirChannels, setTheirChannels] = useState(null);
  const [userCoordinates, setUserCoordinates] = useState({});
  const [userDistance, setUserDistance] = useState('calculating');
  const [counterpartDistance, setCounterpartDistance] = useState('not_joined');
  const [counterpartArrived, setCounterpartArrived] = useState('');
  const [userArrived, setUserArrived] = useState('');
  const [distanceSentCount, setDistanceSentCount] = useState(null);
  const [userLastUpdatedAt, setUserLastUpdatedAt] = useState(null);
  const [counterpartLastUpdatedAt, setCounterpartLastUpdatedAt] = useState(null);

  // Getting Meetup record info
  // onMount - loads once only
  useEffect(()=>{
    setMeetupLoading(true);
    let subscribeMine;
    let subscribeTheirs;
    let urlMeetup;
    let intervalCount = 0;

    // fetch meetupList that includes all meetups that you are joined
    apiCall("GET", `/meetups/get_meetup_lists`,
      csrfToken,
      null)
      .then(res=>{
        // start subscription only if data fetch successful
        //Starting your channel to send your distance.
        subscribeMine = consumer.subscriptions.create(
          { channel: 'MeetupChannel', person_id: res.data[0].current_person_id },
          {
            connected: () => setOwnChannel(subscribeMine),
            received: (received) => {
            },
          }
        );

        //Subscribing to channels of all of your countarparts. See app/channels/meetup_channel.rb
        subscribeTheirs = consumer.subscriptions.create(
          { channel: 'MeetupChannel' },
          {
            connected: () => setTheirChannels(subscribeTheirs),
            received: (received) => {
              //urlKey exists if you are on meetups page
              if (urlKey && received.meetupKey == urlKey) {
                // check if counterpart user arrived or already arrived
                if(received.userArrived) {
                  setCounterpartArrived(received.userArrived)
                  if(received.userArrived === true) {
                    setCounterpartArrived(t('react.meetups.arrived'));
                  }
                } else if (received.userLate){
                  setCounterpartArrived(t('react.meetups.will_be_late_for', {minutes: received.userLate}));
                }
                // check if counterpart distance has been changed
                if (received.distance && received.distance !== counterpartDistance) {
                  setCounterpartDistance(received.distance);
                }
                setCounterpartLastUpdatedAt(new Date());
              }
            },
          }
        );


        // set state values
        setMeetupList(res.data);

        // Only for meetups page
        if (urlKey){
          //Find the particular meetup of the page
          res.data.forEach(function(obj){
            if (obj.meetupkey == urlKey){
              urlMeetup = obj;
              //Get the saved distance of the counterpart if it is present
              if (obj.counterpart_distance !== null){
                setCounterpartDistance(obj.counterpart_distance);
                setCounterpartLastUpdatedAt(obj.counterpart.updated_at);
              }
            }
          });

          // set state values
          setMeetupLoading(false);
          setMeetupLoaded(true);
          setMeetupData(urlMeetup);
          setMeetupPerson(urlMeetup.person_status);
          //Set Arrived or delay for the counterpart
          if(urlMeetup.counterpart){
            if(urlMeetup.counterpart.arrived === true) {
              setCounterpartArrived(t('react.meetups.arrived'));
            }
            else if (urlMeetup.counterpart.late_min){
              setCounterpartArrived(t('react.meetups.will_be_late_for', {minutes: urlMeetup.counterpart.late_min}));
            }
          }
          //Set Arrived or delay for yourself 
          if(urlMeetup.myself){
            if(urlMeetup.myself.arrived === true) {
              setUserArrived(t('react.meetups.arrived'));
            }
            else if (urlMeetup.myself.late_min){
              setUserArrived(t('react.meetups.will_be_late_for', {minutes: urlMeetup.myself.late_min}));
            }
          }
        }

        // Wait for 1 sec to get non-NaN first geo coordinates
        setTimeout(function(){
          setDistanceSentCount(0);
        }, 1000);

        // Subsequent sendLocation interval defined in config
        const interval = setInterval(() => {
          intervalCount += 1;
          setDistanceSentCount(intervalCount);
        },  config.meetups.locationRefreshInterval);
      })
      .catch(err => {
        setMeetupLoading(false);
        setMeetupLoaded(false);
        setMeetupError(err);
        console.log(err);
      });
    return () => {
      // unsubscribe only if channel was created
      if (subscribeMine) {
        subscribeMine.unsubscribe()
      }
      if (subscribeTheirs) {
        subscribeTheirs.unsubscribe()
      }
      if (interval){
        clearInterval(interval);
      }
    }
  }, [])

  //Run anytime inervalCount changes
  //Send distance constantly to channel, and also save to database if moved more than minimumMoveToUpdate
  useEffect(() => {
    let urlDistance;
    //Array of distance each for a meetup to save to database
    let distancesToSave = [];
    //meetupList is the previous values
    let updatedMeetupList = meetupList;

    // Getting current users' coordinates
    if (ownChannel && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserCoordinates({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          });

          //Calculate distance to the meetup location for each meetup and send it to your own channel
          meetupList.forEach(function (meetup, index) {
            // check for meetup
            if (meetup && meetup.latitude) {
              const distance = getDistance(userCoordinates,
                { latitude: meetup.latitude, longitude: meetup.longitude }, 1);
              if (!isNaN(distance)) {
                sendDistance(distance, meetup.meetupkey, meetup.person_status);
                // if it is the particular meetup of the page, store it to urlDistance
                if (meetup.meetupkey == urlKey){
                  urlDistance = distance;
                }

                // If the new distance is different more than the minimum set in config from the previous one, store it to updatedMeetupList
                if (meetup.my_distance == null || Math.abs(meetup.my_distance - distance) >= config.meetups.minimumMoveToUpdate){
                  distancesToSave.push({ meetupId: meetup.meetup_id, distance: distance});
                  updatedMeetupList[index].my_distance = distance;
                }
              }
            }
          });

          //If distancesToSave is not empty, call api
          if (distancesToSave.length !== 0){
            setMeetupList(updatedMeetupList);
            //apiCall to update participant.distance
            apiCall("patch", "/meetups/update_distances",
              csrfToken,
              distancesToSave )
              .then((res) => {
              })
              .catch((err) => console.log("Error", err));
          }

          //setState with the distance of the page meetup
          if (urlDistance) {
            setUserDistance(urlDistance);
            setUserLastUpdatedAt(new Date());
          }
          setMeetupLoading(false);
        },
        (error) => {
          console.log("getCurrentPosition error: ", error);
        },
        {
          enableHighAccuracy: true
        }
      );
    }
    return () => {}
  }, [ownChannel, distanceSentCount]);

  // Sending distance to your own channel with meetupKey 
  const sendDistance = (distance, meetupKey, thisPerson) => {
    if (!isNaN(distance)) {
      ownChannel.send({thisPerson: thisPerson, distance: distance, meetupKey: meetupKey, count: distanceSentCount});
    }
  }

  //If you are on the meetups page, show UI
  if (urlKey){
    return (
      <>
        {
          meetupLoading &&(
            <Spinner animation="border" role="status">
              <span className="visually-hidden" ></span>
            </Spinner>
          )
        }
        {

          meetupLoaded ? (
            <MeetupNotification 
              meetupPerson={meetupPerson}
              personName={meetupData.counterpart_name}
              meetupData={meetupData}
              ownChannel={ownChannel}
              urlKey={urlKey}
              userDistance={userDistance}
              userArrived={userArrived}
              userLastUpdatedAt={userLastUpdatedAt}
              counterpartDistance={counterpartDistance}
              counterpartArrived={counterpartArrived}
              counterpartLastUpdatedAt={counterpartLastUpdatedAt}
            />
          ) : null
        }
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}
