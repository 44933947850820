import dayjs from "dayjs";

//  Global constant values

// An array representing days of the week.
const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

// An array representing the time slots from 8 am to 8pm in a day.
const timeSlots = [
  "8 am",
  "9 am",
  "10 am",
  "11 am",
  "12 pm",
  "1 pm",
  "2 pm",
  "3 pm",
  "4 pm",
  "5 pm",
  "6 pm",
  "7 pm",
  "8 pm",
];

// The default height for a time slot representation in pixels.
const defaultTimeSlotHeight = 60;

// A constant representing a start date for the week.
const originDate = new Date("2023-01-01T00:00:00");

// Format the given time into the format "h:mma"
const formatTime = (time) => {
  const formattedTime = dayjs("2023-01-01 " + time, "YYYY-MM-DD HH:mm").format(
    "h:mma"
  );
  return formattedTime;
};

// Combine timeString and dateString into a formatted date-time string
const formatDateTime = (timeString, dateString) => {
  const formattedDateTime =
    dayjs(dateString).format("YYYY-M-DD") + " " + timeString;
  return formattedDateTime;
};

// Generate an updated time slot object with start and end times
const generateUpdatedTimeSlot = (startTime, endTime, dayIndex) => {
  const currDate = dayjs(originDate).add(dayIndex, "day");
  let formattedStDateTime = formatDateTime(startTime, currDate);
  let formattedEdDateTime = formatDateTime(endTime, currDate);

  return {
    startTime: formattedStDateTime,
    endTime: formattedEdDateTime,
  };
};

// Add given minutes to a time and return the new time
const addMinutesToTime = (minutesToAdd, time) => {
  const [prevHr, prevMin = 0] = String(time).split(":").map(Number);
  const timeSlot = dayjs(originDate).add(prevHr, "hour");
  const newSlot = dayjs(timeSlot).add(minutesToAdd + prevMin, "minute");
  const formattedTime = dayjs(newSlot).format("H:mm");

  return formattedTime;
};

// Create initial start and end timings based on the time index
const createInitTimings = (timeIndex) => {
  const initStTime = 8 + timeIndex;
  const st = `${initStTime}:00`;
  const et = `${initStTime + 1}:00`;

  return { initSt: st, initEt: et };
};

// Find the index of a given hour within the timeSlots array
const findTimeIndex = (hour) => {
  const formattedHour = dayjs().hour(hour).format("h a");
  return timeSlots.indexOf(formattedHour);
};

// Calculate the difference between two times in minutes
function calcTimeDiffInMinutes(time1, time2) {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  const diffInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

  return diffInMinutes;
}

// Convert an array of timings into a structured object
const convertTimingsToObject = (timings) => {
  const id = String(timings[0]);
  let startTimeSlot = timings[1].split("T")[1].split(".")[0];
  let endTimeSlot = timings[2].split("T")[1].split(".")[0];
  const durationMinutes = calcTimeDiffInMinutes(startTimeSlot, endTimeSlot);
  startTimeSlot = startTimeSlot.split(":");
  const startHour = parseInt(startTimeSlot[0]);
  endTimeSlot = endTimeSlot.split(":");
  const dayIndex = parseInt(timings[1].split("T")[0].split("-")[2]) - 1;
  const timeIndex = findTimeIndex(startHour);
  const isEmpty = !(startTimeSlot?.length > 0);

  if (dayIndex >= 0 && timeIndex >= 0) {
    return {
      id,
      durationMinutes,
      startTime: `${startTimeSlot[0]}:${startTimeSlot[1]}`,
      endTime: `${endTimeSlot[0]}:${endTimeSlot[1]}`,
      dayIndex,
      isEmpty,
    };
  } else {
    return {};
  }
};

// Create a grid of time slots based on available timings
const createTimeGrid = (availableTimings) => {
  return new Promise((resolve) => {
    const timeGrid = [];
    if (availableTimings !== undefined) {
      availableTimings.forEach((timing) => {
        const convertedTiming = convertTimingsToObject(timing);

        const timeSlotObj = {
          id: convertedTiming.id,
          height: convertedTiming.durationMinutes,
          startTime: convertedTiming.startTime,
          endTime: convertedTiming.endTime,
          dayIndex: convertedTiming.dayIndex,
          isEmpty: convertedTiming.isEmpty,
        };
        timeGrid.push(timeSlotObj);
      });
    }
    resolve(timeGrid);
  });
};

// Retrieve a timing from the time grid by its ID
const getTimingById = (timeGrid, id) => {
  for (let i = 0; i < timeGrid.length; i++) {
    if (timeGrid[i].id === id) {
      return timeGrid[i];
    }
  }
  return null;
};

// Update a time based on its position after being dragged
const updateTimeAfterDrag = (time, posY) => {
  const [hours, minutes] = time.split(":").map(Number);
  const newTime = dayjs(dayjs(originDate).add(hours, "hour")).add(
    posY + minutes,
    "minute"
  );
  return `${newTime.hour()}:${newTime.minute()}`;
};

// Calculate the X and Y position for a time slot in a grid
const calculatePosXY = (width, dayIndex, timeIndex, startMinutes) => {
  const posX = width * (dayIndex + 1) + (dayIndex/2)
  const posY = defaultTimeSlotHeight * timeIndex + parseInt(startMinutes);
  
  return { x: posX, y: posY };
};

// Find overlapping time slots within a given time grid
const findOverlappingSlots = (startTime, endTime, dayIndex, id, timeGrid) => {
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);
  const overlappingSlots = [];

  for (const slot of timeGrid) {
    if (dayIndex === slot.dayIndex && id !== slot.id) {
      const slotStartMinutes = timeToMinutes(slot.startTime);
      const slotEndMinutes = timeToMinutes(slot.endTime);

      if (
        (startMinutes >= slotStartMinutes && startMinutes <= slotEndMinutes) ||
        (endMinutes >= slotStartMinutes && endMinutes <= slotEndMinutes) ||
        (startMinutes <= slotStartMinutes && endMinutes >= slotEndMinutes)
      ) {
        overlappingSlots.push(slot);
      }
    }
  }

  // Sort overlappingSlots array based on start time in ascending order
  overlappingSlots.sort((slotA, slotB) => {
    const startTimeA = timeToMinutes(slotA.startTime);
    const startTimeB = timeToMinutes(slotB.startTime);
    return startTimeA - startTimeB;
  });

  return overlappingSlots;
};


// Convert a time into its equivalent value in minutes
const timeToMinutes = (time)=> {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

// Merge overlapping time slots into a single slot
const mergeSlots = (initSt, initEt, overlappingSlots)=> {
  let mergedStartTime = timeToMinutes(initSt);
  let mergedEndTime = timeToMinutes(initEt);

  for (const overlappingSlot of overlappingSlots) {
    const overlappingStartTime = timeToMinutes(overlappingSlot.startTime);
    const overlappingEndTime = timeToMinutes(overlappingSlot.endTime);

    mergedStartTime = Math.min(mergedStartTime, overlappingStartTime);
    mergedEndTime = Math.max(mergedEndTime, overlappingEndTime);
  }

  return {
    mergedStartTime: formatMinutesAsTime(mergedStartTime),
    mergedEndTime: formatMinutesAsTime(mergedEndTime),
  };
}

// Format a given number of minutes into the format "hh:mm"
const formatMinutesAsTime =(minutes)=> {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
}

export {
  days,
  timeSlots,
  defaultTimeSlotHeight,
  originDate,
  calculatePosXY,
  updateTimeAfterDrag,
  calcTimeDiffInMinutes,
  createInitTimings,
  generateUpdatedTimeSlot,
  formatTime,
  mergeSlots,
  findOverlappingSlots,
  formatDateTime,
  findTimeIndex,
  addMinutesToTime,
  createTimeGrid,
  getTimingById,
  timeToMinutes,
  formatMinutesAsTime
};
