import React from 'react';
import { createContext,useMemo, useContext } from 'react';
import config from '../configs';
const AppSettings = createContext(null)

AppSettings.csrfToken = null;
AppSettings.lang = config.localization.defaultLocale;

export const useAppSettings = () => {
    const context = useContext(AppSettings)
    if (context === undefined) {
        throw new Error('useAppSettings must be used within a AppSettingsProvider')       }
    return context
}

const AppSettingsProvider = ({ csrfToken, lang, dictionary, children }) => {
    const valueObject = useMemo(() => {
        return { csrfToken, lang, dictionary }
    }, [csrfToken, lang, dictionary])

    return (<AppSettings.Provider value={valueObject}>{children}</AppSettings.Provider>)
}
export default AppSettingsProvider;
