import React, { memo, useContext, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { deleteImage, arrowUp,arrowDown } from "../../../assets/images";
import SchedulerContext from "../../contexts/SchedulerContext";
import { Rnd } from "react-rnd";
import {
  defaultTimeSlotHeight,
  findTimeIndex,
  calculatePosXY,
  updateTimeAfterDrag,
  formatTime,
  addMinutesToTime,
} from "./helper";

const TimeSlot = memo(function TimeSlot({
  width,
  posX,
  posY,
  checkOverlapAndMerge,
  updateTimeGrid,
  id,
  dayIndex,
  startTime,
  endTime,
  height,
  isEmpty,
}) {
  
  const { editable } = useContext(SchedulerContext); // Accessing the editable flag from the Scheduler context to determine if the slot can be modified

  const [hasTimeSlot, setHasTimeSlot] = useState(false); // Boolean to track if the cell (time slot) has been clicked on
  const [timeSlotPos, setTimeSlotPos] = useState({}); // Position state for the time slot

  // Boolean flags to determine if the slot can be dragged or resized
  const [isDraggable, setIsDraggable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);

  // The currently displayed start and end times of the slot
  const [currStartTime, setCurrStartTime] = useState();
  const [currEndTime, setCurrEndTime] = useState();

  const selectedRangeRef = useRef(); // Ref to div containing timeSlot


  const isSmallScreen = window.innerWidth <= 1024; // checks screen

  // Set the time slot position based on changes to posX or posY
  useEffect(() => {
    setTimeSlotPos({ x: posX, y: posY })
   
  }, [posX, posY]);

  // Update the start and end times based on changes to startTime, endTime, or isEmpty
  useEffect(() => {
    const timeSlot = !isEmpty;
    setHasTimeSlot(timeSlot);

    if (startTime && endTime) {
      updateTime(startTime, endTime);
    }
  }, [startTime, endTime, isEmpty]);

  // Helper function to update the current start and end times
  const updateTime = (start, end) => {
    setCurrStartTime(start);
    setCurrEndTime(end);
  };

  // Handle the delete action for the time slot
  const handleDelete = (e) => {
    e.stopPropagation();
    if (!hasTimeSlot) return;

    setHasTimeSlot(false);
    setIsResizable(true);
    setIsDraggable(false);

    const newTimeGrid = {
      dayIndex,
      id,
      isEmpty: true,
      startTime: "00:00",
      endTime: "00:00",
      height: defaultTimeSlotHeight,
    };
    updateTimeGrid(newTimeGrid);
  };

  // Rnd function, triggered when the drag action starts
  const handleDragStart = (e, d) => {
    e.preventDefault();
    setIsResizable(!isResizable);
  };

  // Rnd function, handle the dragging action of the time slot
  const handleDrag = (e, d) => {
    e.preventDefault();
    const posY = Math.round(d.deltaY / 5) * 5;
    if (!isDraggable) {
      setCurrStartTime(updateTimeAfterDrag(currStartTime, posY));
      setCurrEndTime(updateTimeAfterDrag(currEndTime, posY));
    }
  };

  //Rnd function, handle the completion of the drag action
  const handleDragStop = (e, d) => {
    // e.preventDefault();
    const splittedCurrTime = currStartTime.split(":"); // splittedCurrTime = [hr, min]
    const newTimeIdx = findTimeIndex(splittedCurrTime[0]);
    const timeSlotPosAfterDrag = calculatePosXY(
      width,
      dayIndex,
      newTimeIdx,
      splittedCurrTime[1]
    );
    setTimeSlotPos(timeSlotPosAfterDrag);

    const newTimeGridObj = {
      dayIndex: dayIndex,
      endTime: currEndTime,
      height: height,
      id: id,
      isEmpty: false,
      startTime: currStartTime,
    };

    checkOverlapAndMerge(newTimeGridObj);
    setIsResizable(true);
  };

  //Rnd function, triggered when the resize action starts
  const handleResizeStart = (e, direction, selectedRangeRef) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggable(!isDraggable);
  };

  //Rnd function, handle the resizing action of the time slot
  const handleResize = (e, direction, selectedRangeRef, delta, position) => {
    e.preventDefault();
    const min = delta.height; // minutes represented by height
    if (direction === "top" && isDraggable) {
      setCurrStartTime(addMinutesToTime(-(height + min), currEndTime));
    } else if (direction === "bottom" && isDraggable) {
      setCurrEndTime(addMinutesToTime(height + min, currStartTime));
    }
  };

  //Rnd function, handle the completion of the resize action
  const handleResizeStop = (
    e,
    direction,
    selectedRangeRef,
    delta,
    position
  ) => {
    e.preventDefault();
    setIsDraggable(false);
    setIsResizable(true);
    let newHeight;

    newHeight = parseInt(selectedRangeRef.style.height);

    const positionAfterResize = { x: timeSlotPos.x, y: position.y };

    setTimeSlotPos(positionAfterResize);

    checkOverlapAndMerge({
      dayIndex,
      id,
      isEmpty,
      startTime: currStartTime,
      endTime: currEndTime,
      height: newHeight,
    });

    setIsDraggable(!isDraggable);
  };

  return (
    <div >
      {hasTimeSlot && (
        <div className="time-slot-wrapper">
          {startTime && posX !== undefined && posY !== undefined && (
            <Rnd
              size={{ height: height, width: width * 0.9 }}
              maxWidth={"95%"}
              minHeight={"30px"}
              className="time-slot"
              data-testid="timeSlot"
              dragGrid={[5, 5]}
              disableDragging={!editable || isDraggable}
              dragAxis="y"
              bounds=".timeSlot-board"
              onDragStart={(e, d) => handleDragStart(e, d)}
              onDrag={(e, d) => handleDrag(e, d)}
              onDragStop={(e, d) => handleDragStop(e, d)}
              resizeGrid={[5, 5]}
              enableResizing={{
                top: editable && !isSmallScreen,
                bottom: editable,
                right: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStart={(e, direction, selectedRangeRef) =>
                handleResizeStart(e, direction, selectedRangeRef)
              }
              onResize={(e, direction, selectedRangeRef, delta, position) =>
                handleResize(e, direction, selectedRangeRef, delta, position)
              }
              onResizeStop={(e, direction, selectedRangeRef, delta, position) =>
                handleResizeStop(
                  e,
                  direction,
                  selectedRangeRef,
                  delta,
                  position
                )
              }
              position={{ x: timeSlotPos.x, y: timeSlotPos.y }}
            >
              <div
                ref={selectedRangeRef}
                className="time-slot-content"
              >
                {editable && (
                  // <div className="arrow-up-img-container">
                    <img
                      data-testid="arrow-up"
                      className="arrow arrow-up"
                      src={arrowUp}
                      alt="arrow-up"
                    />
                  // </div>
                )}
                <span className="time-span">
                  {currStartTime ? formatTime(currStartTime) : ""}
                  <br />

                  {currEndTime ? formatTime(currEndTime) : ""}
                </span>
                {editable && (
                // <div className="arrow-down-img-container">
                  <img
                    data-testid="arrow-down"
                    className="arrow arrow-down"
                    src={arrowDown}
                    alt="arrow-down"
                  />
                // </div>
                )}
              </div>
              {editable && (
                <div className="delete-img-container">
                  <img
                    data-testid="delete"
                    onTouchEnd={(e) => handleDelete(e)}
                    className="delete-image"
                    src={deleteImage}
                    style={{
                      display: hasTimeSlot ? "block" : "none",
                    }}
                    alt="delete"
                    onClick={(e) => handleDelete(e)}
                  />
                </div>
              )}
            </Rnd>
          )}
        </div>
      )}
    </div>
  );
});

TimeSlot.propTypes = {
  width: PropTypes.number.isRequired,
  posX: PropTypes.number.isRequired,
  posY: PropTypes.number.isRequired,
  checkOverlapAndMerge: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dayIndex: PropTypes.number.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  height: PropTypes.number.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default TimeSlot;
